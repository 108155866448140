import classNames from 'classnames'
import React, { FunctionComponent, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import SweetAlert from 'react-bootstrap-sweetalert'
import { useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import { createStructuredSelector } from 'reselect'
import * as yup from 'yup'
import Button from '../../components/Buttons/Button'
import FlatIcon from '../../components/Icon/FlatIcon'
import { LangItemPartType } from '../../components/LangItem/types'
import Config from '../../config'
import { IAuthParameters, IAuth2FaParameters } from '../../services/api/service/authenticate/types'
import { authProcess2FaAction, authProcessAction } from '../../store/auth/actions'
import {
    makeSelectAuthError,
    makeSelectAuthFetching,
    makeSelect2FaError,
    makeSelect2FaFetching,
    makeSelect2FaMobilePhone,
    makeSelectRequire2Fa,
} from '../../store/auth/selectors'
import AssistanceModal from '../Assistance/AssistanceModal'
import LangSwitcher from '../LanguageProvider/LangSwitcher'
import LoginFormStep from './Step/LoginFormStep'
import PasswordFormStep from './Step/PasswordFormStep'
import Login2FaFormStep from './Step/Login2FaFormStep'
import Logo from '../../assets/svg/logo-u10.svg'
import { yupResolver } from '@hookform/resolvers/yup'
import { IApplicationRootState } from '../../store'
import { IAppErrorTypes } from '../../store/app/types'
import isUndefined from 'lodash/isUndefined'
import { isAxiosError, isConstraintViolationList } from '../../services/api/utils'
import { ILoginPassword2FaStepFormData } from './types'
import { useChromeExtensionHostToken } from '../../utils/hook/useChromeExtensionHostToken'
import get from 'lodash/get'

const stateSelector = createStructuredSelector<any, any>({
    loginError: makeSelectAuthError(),
    loginFetching: makeSelectAuthFetching(),
    auth2FaFetching: makeSelect2FaFetching(),
    auth2FaMobilePhone: makeSelect2FaMobilePhone(),
    auth2FaError: makeSelect2FaError(),
    require2Fa: makeSelectRequire2Fa(),
})

/**
 * Utilisation d'un FunctionComponent pour typer les props (RouteComponentProps), injectées par le composant de page LoginPage
 * tout en offrant la possibilité de ne pas utiliser les props (facultatives)
 * @constructor
 */
const LoginWindow: FunctionComponent<RouteComponentProps> = (): JSX.Element => {
    const { formatMessage } = useIntl() //hook react-intl permettant de récupérer l'api FormattedMessage
    const dispatch = useDispatch()
    const { loginFetching, loginError, auth2FaError, auth2FaFetching, require2Fa, auth2FaMobilePhone } = useSelector<
        IApplicationRootState,
        {
            loginError?: IAppErrorTypes
            loginFetching: boolean
            require2Fa: boolean
            auth2FaError?: IAppErrorTypes
            auth2FaFetching: boolean
            auth2FaMobilePhone?: string | null
        }
    >(stateSelector)

    const [showError, setShowError] = useState<boolean>(false)
    const [isFromPrev, setIsFromPrev] = useState<boolean>(false)
    const [isFromNext, setIsFromNext] = useState<boolean>(false)
    const [currentStep, setCurrentStep] = useState<number>(1)
    const [helpShown, setHelpShown] = useState<boolean>(false)

    const remoteError = useMemo(() => {
        return loginError || auth2FaError
    }, [loginError, auth2FaError])

    const fetching = useMemo(() => {
        return loginFetching || auth2FaFetching
    }, [loginFetching, auth2FaFetching])

    // ATENTION: On a découpé en step le login. De ce fait, on aussi des contraintes dans chaque step
    // ICI, ce n'est que la dernière verification
    const validationSchema = useMemo(() => {
        return yup.object().shape({
            login: yup.string(),
            password: yup.string(),
            code: yup.string(),
        })
    }, [])

    const isUnauthorizedError = useMemo(() => {
        return get(remoteError, 'status') === 401
    }, [remoteError])

    const unauthorizedIcon = useMemo(() => {
        return <img src={Logo} alt={Config.APP_NAME} className="swal-logo" />
    }, [])

    const { register, handleSubmit, getValues, setError, clearErrors, reset } = useForm<
        yup.InferType<typeof validationSchema>
    >({
        resolver: yupResolver(validationSchema),
    })

    const hostToken = useChromeExtensionHostToken()

    const formData = getValues()

    // NOTE DEV: pas de useCallback car cela plante react-hook-form
    const onSubmit = (data: IAuthParameters & IAuth2FaParameters): void => {
        dispatch(authProcessAction({ ...data, host_token: hostToken }))
        setShowError(false)
    }

    // NOTE DEV: pas de useCallback car cela plante react-hook-form
    const handlePasswordValidated = (): void => {
        handleSubmit(onSubmit)()
    }

    const focusInUser = useCallback((step: number) => {
        // récupération de la bonne step
        const activeStep = document.querySelector(`.login-step-item-${step}`)
        if (!activeStep) {
            return
        }
        const input = activeStep.querySelector('input[type="text"], input[type="number"], input[type="password"]')
        if (!input) {
            return
        }

        // focus
        setTimeout(() => {
            ;(input as HTMLInputElement).focus()
        }, 250)
    }, [])

    const handleStepChange = useCallback(
        (step: number) => {
            setShowError(false)
            setCurrentStep(step)
        },
        [setShowError, setCurrentStep]
    )

    const handleHelpHide = useCallback(() => setHelpShown(false), [setHelpShown])
    const handleNeedHelpButtonClick = useCallback(() => setHelpShown(true), [setHelpShown])
    const handlePreviousButtonClick = useCallback(
        (step: number): void => {
            const stepNew: number = step - 1
            setCurrentStep(stepNew)
            setIsFromNext(false)
            setIsFromPrev(true)
        },
        [setCurrentStep, setIsFromNext, setIsFromPrev]
    )

    const handleUsernameClicked = useCallback((): void => {
        if (require2Fa) {
            return
        }

        setCurrentStep(1)
        setIsFromNext(false)
        setIsFromPrev(true)
    }, [setCurrentStep, setIsFromNext, setIsFromPrev, require2Fa])

    const handleLoginValidated = useCallback(
        (_, step: number): void => {
            const stepNew: number = step + 1
            setCurrentStep(stepNew)
            setIsFromNext(true)
            setIsFromPrev(false)
        },
        [setCurrentStep, setIsFromNext, setIsFromPrev]
    )

    const handle2FaValidated = useCallback(
        (data: ILoginPassword2FaStepFormData): void => {
            if (!data.code) {
                return
            }
            dispatch(authProcess2FaAction(data.code))
        },
        [dispatch]
    )

    const handleShowErrorConfirm = useCallback((): void => {
        setShowError(false)
        focusInUser(currentStep)
    }, [setShowError, focusInUser, currentStep])

    useEffect(() => {
        // reset des erreurs
        clearErrors()

        // on cache la modal si ce n'est pas déjà fait
        if (isUndefined(remoteError)) {
            setShowError(false)
            return
        }

        // on register les erreurs
        if (isAxiosError(remoteError) && remoteError.response && isConstraintViolationList(remoteError.response.data)) {
            remoteError.response.data.violations.forEach((violation) => {
                if (violation.propertyPath) {
                    // @ts-ignore
                    setError(violation.propertyPath, {
                        message: violation.message,
                    })
                }
            })
        }

        // on affiche la modal
        setShowError(true)
    }, [remoteError, setShowError, setError, clearErrors])

    useEffect(() => {
        if (require2Fa) {
            setCurrentStep(3)
        }
    }, [setCurrentStep, require2Fa])

    useEffect(() => {
        focusInUser(currentStep)
    }, [currentStep, focusInUser])

    return (
        <div className={'login-window'}>
            <div className="login-window-body">
                <div className={'login-window-body-heading'}>
                    <div className="heading">
                        <img src={Logo} alt={Config.APP_NAME} />
                        <h1>
                            <FormattedMessage id={'default.welcome'} />
                        </h1>
                        {currentStep === 1 && (
                            <h6>
                                <FormattedMessage id={'login.use_platform_login'} values={{ name: Config.APP_NAME }} />
                            </h6>
                        )}
                        {currentStep > 1 && formData.login && (
                            <h6 className={'user-name-heading'} onClick={handleUsernameClicked}>
                                <FlatIcon icon={'user'} />
                                <span className={'value'}>{formData.login}</span>
                            </h6>
                        )}
                    </div>
                </div>
                {/*<MaintenanceAlert />*/}
                <Form
                    noValidate
                    onSubmit={handleSubmit(onSubmit)}
                    onReset={() => {
                        reset()
                    }}
                >
                    <div className={classNames('login-step-list', `login-step-list-active-${currentStep}`)}>
                        <div
                            className={classNames(
                                'login-step-item',
                                'login-step-item-1',
                                { active: currentStep === 1 },
                                { 'from-next': currentStep === 1 && isFromNext },
                                { 'from-prev': currentStep === 1 && isFromPrev },
                                { inactive: currentStep !== 1 }
                            )}
                        >
                            <LoginFormStep
                                step={1}
                                register={register}
                                fetching={fetching}
                                disabled={fetching}
                                jumpToStep={handleStepChange}
                                onValidated={handleLoginValidated}
                                nextButtonText={formatMessage({
                                    id: 'default.next',
                                })}
                            />
                        </div>
                        <div
                            className={classNames(
                                'login-step-item',
                                'login-step-item-2',
                                { active: currentStep === 2 },
                                { 'from-next': currentStep === 2 && isFromNext },
                                { 'from-prev': currentStep === 2 && isFromPrev },
                                { inactive: currentStep !== 2 }
                            )}
                        >
                            <PasswordFormStep
                                step={2}
                                register={register}
                                fetching={fetching}
                                disabled={fetching}
                                jumpToStep={handleStepChange}
                                onValidated={handlePasswordValidated}
                                onPreviousButtonClick={handlePreviousButtonClick}
                                previousButtonText={formatMessage({
                                    id: 'default.previous',
                                })}
                                nextButtonText={formatMessage({
                                    id: 'login.connection',
                                })}
                            />
                        </div>
                        <div
                            className={classNames(
                                'login-step-item',
                                'login-step-item-3',
                                { active: currentStep === 3 },
                                { 'from-next': currentStep === 3 && isFromNext },
                                { 'from-prev': currentStep === 3 && isFromPrev },
                                { inactive: currentStep !== 3 }
                            )}
                        >
                            <Login2FaFormStep
                                step={3}
                                register={register}
                                fetching={fetching}
                                required={require2Fa}
                                disabled={fetching || !require2Fa}
                                jumpToStep={handleStepChange}
                                onValidated={handle2FaValidated}
                                nextButtonText={formatMessage({
                                    id: 'login.connection',
                                })}
                                mobilePhone={auth2FaMobilePhone}
                            />
                        </div>
                    </div>
                </Form>
            </div>
            <div className={'login-window-footer'}>
                <Row>
                    <Col className={'col-login-window-lang'}>
                        <LangSwitcher
                            itemParts={[LangItemPartType.Flag, LangItemPartType.Native, LangItemPartType.Name]}
                        />
                    </Col>
                    <Col className={'col-login-window-help'}>
                        <Button variant={'link'} onClick={handleNeedHelpButtonClick}>
                            <FormattedMessage id={'default.need_help'} />
                        </Button>
                    </Col>
                </Row>
            </div>
            <SweetAlert
                customClass="swal-login-error"
                custom
                title={formatMessage({
                    id: !isUnauthorizedError ? 'default.error' : 'seo.login.description',
                })}
                show={showError}
                onConfirm={handleShowErrorConfirm}
                customIcon={unauthorizedIcon}
                openAnim={{ name: 'fadeInDown', duration: 500 }}
                closeAnim={{ name: 'fadeOutUp', duration: 500 }}
            >
                {remoteError?.message}
            </SweetAlert>
            <AssistanceModal show={helpShown} onHide={handleHelpHide} onExit={handleHelpHide} />
        </div>
    )
}

export default memo(LoginWindow)
