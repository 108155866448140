import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios'
import jwtDecode from 'jwt-decode'
import HttpStatusCode from '../../../../../store/http/codes'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'

// a little time before expiration to try refresh (seconds)
const EXPIRE_FUDGE = 10
export const STORAGE_KEY = `auth-tokens-${process.env.NODE_ENV}`

type Token = string
export interface IAuthTokens {
    accessToken: Token
    refreshToken: Token
}

// EXPORTS

/**
 * Checks if refresh tokens are stored
 * @returns Whether the user is logged in or not
 */
export const isLoggedIn = (): boolean => {
    const token = getRefreshToken()
    return !!token
}

/**
 * Sets the access and refresh tokens
 * @param {IAuthTokens} tokens - Access and Refresh tokens
 */
export const setAuthTokens = (tokens: IAuthTokens): void => localStorage.setItem(STORAGE_KEY, JSON.stringify(tokens))

/**
 * Sets the access token
 * @param {string} token - Access token
 */
export const setAccessToken = (token: Token): void => {
    const tokens = getAuthTokens()
    if (!tokens) {
        throw new Error('Unable to update access token since there are not tokens currently stored')
    }

    tokens.accessToken = token
    setAuthTokens(tokens)
}

/**
 * Clears both tokens
 */
export const clearAuthTokens = (): void => localStorage.removeItem(STORAGE_KEY)

/**
 * Returns the stored refresh token
 * @returns {string} Refresh token
 */
export const getRefreshToken = (): Token | undefined => {
    const tokens = getAuthTokens()
    return tokens ? tokens.refreshToken : undefined
}

/**
 * Returns the stored access token
 * @returns {string} Access token
 */
export const getAccessToken = (): Token | undefined => {
    const tokens = getAuthTokens()
    return tokens ? tokens.accessToken : undefined
}

/**
 *
 * @param {Axios} axios - Axios instance to apply the interceptor to
 * @param {IAuthTokenInterceptorConfig} config - Configuration for the interceptor
 */
export const applyAuthTokenInterceptor = (axios: AxiosInstance, config: IAuthTokenInterceptorConfig): void => {
    if (!axios.interceptors) throw new Error(`invalid axios instance: ${axios}`)

    const { requestRefresh, header = 'Authorization', headerPrefix = 'Bearer ', whitelist } = config

    axios.interceptors.request.use(
        async (requestConfig: AxiosRequestConfig) => {
            if (isArray(whitelist) && isString(requestConfig.url) && whitelist?.indexOf(requestConfig.url) > -1) {
                return requestConfig
            }

            // use access token (if we have it)
            const accessToken = getAccessToken()

            if (accessToken && requestConfig.headers) requestConfig.headers[header] = `${headerPrefix}${accessToken}`

            return requestConfig
        },
        (error) => {
            return Promise.reject(error)
        }
    )

    axios.interceptors.response.use(
        (response) => response, // Directly return successful responses.
        async (error: AxiosError) => {
            const originalRequest = error.config as any

            if (error.response?.status === HttpStatusCode.UNAUTHORIZED && !originalRequest._retry) {
                originalRequest._retry = true // Mark the request as retried to avoid infinite loops.
                try {
                    // Make a request to your auth server to refresh the token.
                    const accessToken = await refreshToken(requestRefresh)

                    // store
                    await setAccessToken(accessToken)

                    // Update the authorization header with the new access token.
                    axios.defaults.headers.common[header] = `${headerPrefix} ${accessToken}`
                    originalRequest.headers[header] = `${headerPrefix} ${accessToken}`

                    return axios(originalRequest) // Retry the original request with the new access token.
                } catch (refreshError) {
                    // Handle refresh token errors by clearing stored tokens and redirecting to the login page.
                    console.error('Token refresh failed:', refreshError)

                    await clearAuthTokens()

                    return Promise.reject(refreshError)
                }
            }

            return Promise.reject(error) // For all other errors, return the error as is.
        }
    )
}

// PRIVATE

/**
 *  Returns the refresh and access tokens
 * @returns {IAuthTokens} Object containing refresh and access tokens
 */
const getAuthTokens = (): IAuthTokens | undefined => {
    const rawTokens = localStorage.getItem(STORAGE_KEY)
    if (!rawTokens) return

    try {
        // parse stored tokens JSON
        return JSON.parse(rawTokens)
    } catch (error: unknown) {
        if (error instanceof SyntaxError) {
            error.message = `Failed to parse auth tokens: ${rawTokens}`
            throw error
        }
    }
}

/**
 * Checks if the token is undefined, has expired or is about the expire
 *
 * @param {string} token - Access token
 * @returns Whether or not the token is undefined, has expired or is about the expire
 */
const isTokenExpired = (token: Token): boolean => {
    if (!token) return true
    const expiresIn = getExpiresIn(token)
    return !expiresIn || expiresIn <= EXPIRE_FUDGE
}

/**
 * Gets the unix timestamp from an access token
 *
 * @param {string} token - Access token
 * @returns {string} Unix timestamp
 */
const getTimestampFromToken = (token: Token): number | undefined => {
    const decoded = jwtDecode<{ [key: string]: number }>(token)

    return decoded?.exp
}

/**
 * Returns the number of seconds before the access token expires or -1 if it already has
 *
 * @param {string} token - Access token
 * @returns {number} Number of seconds before the access token expires
 */
const getExpiresIn = (token: Token): number => {
    const expiration = getTimestampFromToken(token)

    if (!expiration) return -1

    return expiration - Date.now() / 1000
}

/**
 * Refreshes the access token using the provided function
 *
 * @param {requestRefresh} requestRefresh - Function that is used to get a new access token
 * @returns {string} - Fresh access token
 */
const refreshToken = async (requestRefresh: TokenRefreshRequest): Promise<Token> => {
    const refreshToken = getRefreshToken()
    if (!refreshToken) throw new Error('No refresh token available')

    try {
        // Refresh and store access token using the supplied refresh function
        const newTokens = await requestRefresh(refreshToken)
        if (typeof newTokens === 'object' && newTokens?.accessToken) {
            await setAuthTokens(newTokens)
            return newTokens.accessToken
        } else if (typeof newTokens === 'string') {
            await setAccessToken(newTokens)
            return newTokens
        }

        throw new Error('requestRefresh must either return a string or an object with an accessToken')
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
        // Failed to refresh token
        const status = error?.response?.status
        if (status === 401 || status === 422) {
            // The refresh token is invalid so remove the stored tokens
            localStorage.removeItem(STORAGE_KEY)
        }
        throw error
    }
}

export type TokenRefreshRequest = (refreshToken: Token) => Promise<Token | IAuthTokens>

export interface IAuthTokenInterceptorConfig {
    header?: string
    headerPrefix?: string
    requestRefresh: TokenRefreshRequest
    whitelist?: Array<string>
}

type RequestsQueue = {
    resolve: (value?: unknown) => void
    reject: (reason?: unknown) => void
}[]
